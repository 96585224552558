var EMVA = EMVA || {};
EMVA.Backgrid = {};


/****************************************************************************
	Backgrid's default Cell won't accept a className, 
		so add that functionality
	
	We also want to add the label as data-label 
		so the css can grab it for small screens.
 ****************************************************************************/
// typing "editable:false" on every cell sucks, so override the default :D
Backgrid.Column.prototype.defaults.editable = false;
Backgrid.Column.prototype.defaults.sortable  = false;
Backgrid.Column.prototype.defaults.cell  	= 'string';


//save the original so we can call it
var backgridCell_initialize_original = Backgrid.Cell.prototype.initialize;
Backgrid.Cell.prototype.initialize = function () {
	//call the original
    backgridCell_initialize_original.apply(this, arguments);
    if(this.column.attributes.className) this.$el.addClass(this.column.attributes.className);
    if(this.column.attributes.label) this.$el.attr('data-label',this.column.attributes.label)
}


/****************************************************************************
	Backgrid's default headerCell won't accept a className,
	so add that functionality
 ****************************************************************************/
EMVA.Backgrid.HeaderCell = Backgrid.HeaderCell.extend({
								render:function(){
									Backgrid.HeaderCell.prototype.render.call(this)
									this.$el.addClass(this.column.attributes.className)
									return this;
								}
							})
// Set this as the default for all tables so we don't have to put it in every column
Backgrid.Column.prototype.defaults.headerCell = EMVA.Backgrid.HeaderCell;

/****************************************************************************
	Backgrid's default boolean expects true/false. Our system stores 0/1. 
		Passing it around then makes it a string. This just converts 
		things to true/false so backgrid's boolean cell will play nice.
 ****************************************************************************/
EMVA.Backgrid.BooleanFormatter = _.extend({}, Backgrid.CellFormatter.prototype, {
									fromRaw: function (rawValue, model) {
										return (parseInt(rawValue)===1)?true:false;
									} })
									
/****************************************************************************
	Backgrid expects an int. Our passing it around makes it a string, so 
		make it an int for Backgrid	
 ****************************************************************************/								
EMVA.Backgrid.IntFormatter = _.extend({}, Backgrid.CellFormatter.prototype, {
									fromRaw: function (rawValue, model) {
										return (!isNaN(parseInt(rawValue)))?parseInt(rawValue):0;
									} })